<template>
  <div>
    <Header>
    </Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'indexN',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
</style>
