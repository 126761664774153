import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import vueSeamlessScroll from 'vue-seamless-scroll'
// 引入css
import 'swiper/css/swiper.min.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'

import BaiduMap from 'vue-baidu-map'

import request from "./utils/request";
Vue.prototype.request = request;

Vue.use(VueAwesomeSwiper)

Vue.use(vueSeamlessScroll)

Vue.config.productionTip = false
Vue.use(ElementUI)

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'owKG9qG7PITJbjzQTQtNmvBDbKSjqWHt'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
