import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/Home",
    component: Index,
    children: [
      {
        path: "Home",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "About1",
        name: "About1",
        component: () => import("../views/About1.vue"),
      },
      {
        path: "About2",
        name: "About2",
        component: () => import("../views/About2.vue"),
      },
      {
        path: "About3",
        name: "About3",
        component: () => import("../views/About3.vue"),
      },
      {
        path: "About4",
        name: "About4",
        component: () => import("../views/About4.vue"),
      },

      {
        path: "contact",
        name: "contact",
        component: () => import("../views/contact.vue"),
      },
      {
        path: "product1",
        name: "product1",
        component: () => import("../views/product1.vue"),
      },
      {
        path: "product2",
        name: "product2",
        component: () => import("../views/product2.vue"),
      },
      {
        path: "product3",
        name: "product3",
        component: () => import("../views/product3.vue"),
      },
      {
        path: "product4",
        name: "product4",
        component: () => import("../views/product4.vue"),
      },
      {
        path: "product1_1",
        name: "product1_1",
        component: () => import("../views/product1_1.vue"),
      },
      {
        path: "product1_2",
        name: "product1_2",
        component: () => import("../views/product1_2.vue"),
      },
      {
        path: "product1_3",
        name: "product1_3",
        component: () => import("../views/product1_3.vue"),
      },
      {
        path: "product2_1",
        name: "product2_1",
        component: () => import("../views/product2_1.vue"),
      },
      {
        path: "product3_1",
        name: "product3_1",
        component: () => import("../views/product3_1.vue"),
      },
      {
        path: "product3_2",
        name: "product3_2",
        component: () => import("../views/product3_2.vue"),
      },
      {
        path: "product4_1",
        name: "product4_1",
        component: () => import("../views/product4_1.vue"),
      },
      {
        path: "news1",
        name: "news1",
        component: () => import("../views/news1.vue"),
      },
      {
        path: "news2",
        name: "news2",
        component: () => import("../views/news2.vue"),
      },
      {
        path: "news2_1",
        name: "news2_1",
        component: () => import("../views/news2_1.vue"),
      },
      {
        path: "news2_2",
        name: "news2_2",
        component: () => import("../views/news2_2.vue"),
      },
      {
        path: "news2_3",
        name: "news2_3",
        component: () => import("../views/news2_3.vue"),
      },
      {
        path: "news2_4",
        name: "news2_4",
        component: () => import("../views/news2_4.vue"),
      },
      {
        path: "news4",
        name: "news4",
        component: () => import("../views/news4.vue"),
      },
    ],
  },
];
const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
