<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import './assets/css/base.css'
export default {

}
</script>

<style>
</style>
