<template>
  <div class="footer">
    <div class="box1">
      <div class="reset_style">
        <div class="box1-l"></div>
        <div class="box1-r">
          <p>
            <span style="color: #ffffff"
              ><span style="font-size: 14px">咨询热线</span></span
            >
          </p>
          <h3>
            <span style="color: #db2001"
              ><span style="line-height: 1.6"
                ><strong
                  ><span style="font-size: 24px">0757-28895980</span></strong
                ></span
              ></span
            >
          </h3>
        </div>
      </div>
      <div class="reset_style">
        <div>
          <p>
            <span style="line-height: 2"
              ><span style="font-family: 微软雅黑"
                ><span style="color: #ffffff"
                  ><span style="font-size: 14px">传真：</span></span
                ><a href="tel:0635-3672899"
                  ><span style="color: #ffffff">0757-28895985</span></a
                ><br />
                <span style="font-size: 14px"
                  ><span style="color: #ffffff">邮箱：</span
                  ><a href="mailto:saierdaogui@126.com"
                    ><span style="color: #ffffff">sdderun01@163.com</span></a
                  ></span
                ></span
              ></span
            >
          </p>
          <p>
            <span style="line-height: 2"
              ><span style="color: #ffffff"
                ><span style="font-family: 微软雅黑"
                  ><span style="font-size: 14px"
                    >地址：广东省佛山市顺德区容桂街道细滘路81号中建创科园C2栋103之一</span
                  ></span
                ></span
              ></span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="navBox">
        <nav>
          <ul class="navUl">
            <li class="navLi">
              <h3 class="navNameH1">
                <a href="/about1.html" class="navName1">走进我们</a>
              </h3>
              <ul class="navEjBox">
                <li class="navLiEj">
                  <a href="/about1" class="navName2">公司简介</a>
                </li>
                <li class="navLiEj">
                  <a href="/about2" class="navName2">企业文化</a>
                </li>
                <li class="navLiEj">
                  <a href="/about3" class="navName2">资质荣誉</a>
                </li>
                <li class="navLiEj">
                  <a href="/about4" class="navName2">公司环境</a>
                </li>
              </ul>
            </li>
            <li class="navLi">
              <h3 class="navNameH1">
                <a href="/product1" class="navName1">产品展示</a>
              </h3>
              <ul class="navEjBox">
                <li class="navLiEj">
                  <a href="/product1" class="navName2">导轨-滑块</a>
                </li>
                <li class="navLiEj">
                  <a href="/product2" class="navName2">丝杠-螺母</a>
                </li>
                <li class="navLiEj">
                  <a href="/product3" class="navName2">轴承</a>
                </li>
                <li class="navLiEj">
                  <a href="/product4" class="navName2">液压</a>
                </li>
              </ul>
            </li>
            <li class="navLi">
              <h3 class="navNameH1">
                <a href="/news1" class="navName1">新闻资讯</a>
              </h3>

              <ul class="navEjBox">
                <li class="navLiEj">
                  <a href="/news1" class="navName2">公司新闻</a>
                </li>
                <li class="navLiEj">
                  <a href="/news2" class="navName2">行业动态</a>
                </li>
              </ul>
            </li>
            <li class="navLi">
              <h3 class="navNameH1">
                <a href="/contact" class="navName1">联系我们</a>
              </h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="box3">
      <div class="img">
        <img src="../assets/images/公众号.jpg" alt="" />
        <p>公众号二维码</p>
      </div>
    </div>
    <div class="box4">
      <div class="a">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >粤ICP备2024286539号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerN",
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 290px;
  background-color: #333333;
}
.reset_style {
  padding: 0 30px;
  margin-top: 10px;
}
.box1 {
  float: left;
  width: 25%;
  height: 220px;
}
.box1-l {
  display: inline-block;
  width: 60px;
  height: 70px;
  background-image: url(../assets/images/db_dh.png);
  background-repeat: no-repeat;
  background-position: 0px 21px;
}
.box1-r {
  display: inline-block;
}
.box2 {
  float: left;
  width: 55%;
  height: 240px;
}
.navBox {
  height: 85%;
  border-right: solid 1.5px rgba(255, 255, 255, 0.2);
  border-left: solid 1.5px rgba(255, 255, 255, 0.2);
}
nav {
  margin-top: 20px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.box2 .navLi {
  position: relative;
  width: calc((100% - 0px) / 4);
  /* display: inline-block; */
  float: left;
}
.navNameH1 {
  line-height: 40px;
  text-align: center;
}
.navName1 {
  font-size: 20px;
  color: rgb(255, 255, 255);
  transition: all 1s ease 0s;
}
.navName2 {
  display: block;
  width: 100%;
  color: rgba(144, 144, 144, 0.95);
  font-size: 15px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 1s ease 0s;
  line-height: 30px;
}
.navLi a:hover {
  color: #27ba9b;
}
.box3 {
  float: left;
  width: 20%;
  height: 220px;
  /* background-color: blue; */
}
.img {
  padding: 22px 55px;
}
.img img {
  width: 150px;
  height: 150px;
}
.img p {
  padding: 10px 22px;
  color: rgba(144, 144, 144, 0.95);
}

.box4 {
  float: left;
  width: 100%;
  height: 50px;
  border-top: 2px solid rgba(144, 144, 144, 0.95);
  /* background-color: blueviolet; */
}
.box4 .a {
  margin: 15px 40%;
}
.box4 .a a {
  color: white;
}
</style>
