<template>
  <div class="box">
    <div class="one wrapper">
      <div class="logo">
        <h1>
          <a href="/Home">德润机械有限公司</a>
        </h1>
      </div>
      <div class="nav">
        <ul>
          <li class="subli">
            <h3>
              <router-link to="/">首页</router-link>
            </h3>
          </li>
          <li class="subli">
            <h3>
              <router-link to="/about1">走进我们</router-link>
            </h3>
            <ul class="sub">
              <li><router-link to="/about1">公司简介</router-link></li>
              <li><router-link to="/about2">企业文化</router-link></li>
              <li><router-link to="/about3">资质荣誉</router-link></li>
              <li><router-link to="/about4">公司环境</router-link></li>

            </ul>
          </li>
          <li class="subli">
            <h3><router-link to="/product1">产品展示</router-link></h3>
            <ul class="sub">
              <li><router-link to="/product1">导轨-滑块</router-link></li>
              <li><router-link to="/product2">丝杠-螺母</router-link></li>
              <li><router-link to="/product3">轴承</router-link></li>
              <li><router-link to="/product4">液压</router-link></li>
            </ul>
          </li>
          <li class="subli">
            <h3><router-link to="/news1">新闻资讯</router-link></h3>
            <ul class="sub">
              <li><router-link to="/news1">公司新闻 </router-link></li>
              <li><router-link to="/news2">行业动态</router-link></li>
            </ul>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerN',

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const scrollTop = window.scrollY
      const box = document.querySelector('.box')
      if (scrollTop >= 45) {
        box.classList.add('sticky')
      } else {
        box.classList.remove('sticky')
      }
    }
  }
}
</script>

<style scoped>
.sticky {
  position: fixed;
  top: 0;
  animation: sticky 1s;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.box {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  /* background-color: #fff;` */
}
.box:hover {
  transition: all 0.5s ease;
  background-color: #fff;
}

/* 最顶部的导航栏 */
.one {
  height: 75px;
  /* background-color: #fff; */
}
.one .logo {
  float: left;
  margin-left: 20px;
  width: 200px;
  height: 75px;
}

/* logo 搜索引擎优化做法 */
.logo h1 {
  width: 200px;
  height: 75px;
}

.one .logo h1 a {
  display: block;
  width: 200px;
  height: 75px;
  background-image: url(../assets/images/logo.png);
  background-size: 200px 75px;
  /* 目的: 让h1里面的字看不见 */
  font-size: 0;
}

.one .nav {
  float: right;
  height: 75px;
}

.nav li {
  float: left;
  position: relative;
  margin-right: 80px;
  line-height: 75px;
}

.nav li a {
  padding-bottom: 7px;
  font-size: 20px;
  font-weight: normal;
  color: #666;
}

.nav li a:hover {
  color: #FF0000;
  border-bottom: 1px solid #FF0000;
}

.nav .sub {
  display: none;
  left: 55%;
  top: 75px;
  height: 0px;
  position: absolute;
  line-height: 26px;
  width: 120px;
  margin-left: -65px;
  z-index: 999;
  background: #ddd;
}

.nav .sub li {
  zoom: 1;
  display: block;
  border-bottom: 1px solid #eeeeee;
  height: 40px;
  width: 120px;
  line-height: 40px;
  background: #eeeeee;
  text-align: center;
}
.sub li a {
  color: #434343;
  font-size: 16px;
}
.nav .subli:hover .sub {
  display: block;
  /* top: 100%; */
}
</style>
